import { Dialog, Toast } from 'vant'
import i18n from '@/i18n'
const dialog = {
  confirm (msg, event, title) {
    Dialog.confirm({
      title: title,// || i18n.global.t('提示')
      message: msg,
      closeOnPopstate: true
    }).then(event).catch(() => { });
  },
  loading (msg) {
    Toast.loading({
      duration: 0,
      message: msg,
      forbidClick: true,
    });
  },
  alert (msg, event, title) {
    if (title) {
      Dialog.alert({
        title: title,// || i18n.global.t('提示')
        message: msg
      }).then(event);
    }
    else {
      Dialog.alert({
        message: msg
      }).then(event);
    }
  }
}
export default dialog