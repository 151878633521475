export default {
  暂无数据: '暂无数据',
  暂无信息: '暂无信息',
  提示: '提示',
  语言: '语言',
  参数无效: '参数无效',
  话费充值: '话费充值',
  免费充值: '免费充值',
  邀请有礼: '邀请有礼',
  个人中心: '个人中心',
  选择语言: '选择语言',
  邮箱:'邮箱',
  邮箱格式错误:"邮箱格式错误",
  请输入邮箱: '请输入邮箱',
  请输入手机号: '请输入手机号',
  请输入密码: '请输入密码',
  忘记: '忘记?',
  我已阅读并同意: '我已阅读并同意',
  与: '与',
  用户协议: '用户协议',
  隐私政策: '隐私政策',
  验证码登录: '验证码登录',
  注册账户: '注册账户',
  登录: '登录',
  登录中: '登录中...',
  其他登录方式: '其他登录方式',
  登录成功: '登录成功',
  请勾选已阅读: '请勾选已阅读',
  验证码登录: '验证码登录',
  密码登录: '密码登录',
  发送验证码: '发送验证码',
  手机号码无效: '手机号码无效',
  验证码发送至: '验证码发送至',
  手机验证码: '手机验证码',
  后可重新获取验证码: '后可重新获取验证码',
  发送成功: '发送成功',
  获取短信验证码: '获取短信验证码',

  手机注册: '手机注册',
  设置密码: '设置密码',
  请输入x位密码: '请输入6-20位密码',
  下一步: '下一步',
  手机号无效: '手机号无效',
  验证码无效: '验证码无效',
  注册成功: '注册成功',

  找回密码: '找回密码',
  参加储值计划获得免费充值卷: '参加储值计划,获得免费充值卷',
  前往: '前往',
  请输入手机号: '请输入手机号',
  开始充值: '开始充值',
  充值: '充值',
  充值2:'',
  号码: '号码',
  更换运营商: '更换运营商',
  折: '折',
  属于运营商: '属于运营商',
  充值号码: '充值号码',
  充值号码2: '充值号码',
  运营商无效: '运营商无效',
  金额无效: '金额无效',
  继续充值: '继续充值',
  安全支付: '安全支付',
  订单详情: '订单详情',
  商品价格: '商品价格',
  优惠券: '优惠券',
  实付金额: '实付金额',
  支付方式: '支付方式',
  总计: '总计',
  立即充值: '立即支付',
  获取用户信息失败: '获取用户信息失败',
  余额不足: '余额不足',
  最小充值: '最小充值',
  无可用优惠券: '无可用优惠券',
  支付结果: '支付结果',
  付款成功: '付款成功',
  付款失败: '付款失败',
  查看订单: '查看订单',
  返回首页: '返回首页',
  邀请好友免费获券: '邀请好友免费获券',
  前往邀请: '前往邀请',
  好友人数: '好友人数',
  海量余额: '海量余额',
  请输入卡号: '请输入卡号',
  请输入姓名: '请输入姓名',
  添加信用卡: '添加信用卡',
  填写您的信用卡信息: '填写您的#信息',
  确定: '确定',
  请选择: '请选择',
  请填写卡号: '请填写卡号',
  请选择有效期: '请选择有效期',
  请填写CVC: '请填写CVC/CVV',
  请填写姓名: '请填写姓名',
  请选择支付方式: '请选择支付方式',
  立即支付: '立即支付',
  储值计划: '储值计划',
  我的储值: '我的储值',
  储值说明: '储值说明',
  购买: '购买',
  领券: '领券',
  团队储值: '团队储值',
  奖励规则: '奖励规则',
  剩余: '剩余',
  剩余2: '剩余',
  邀请好友赚直充券加余额: '邀请好友赚直充券+余额',
  仅话费充值可用: '仅话费充值可用',
  直充券: '直充券',
  余额: '余额',
  立即邀请: '立即邀请',
  二维码: '二维码',
  个人资料: '个人资料',
  立即登录: '立即登录',
  余额可提现: '余额(可提现)',
  个人储值: '个人储值',
  团队储值2: '团队储值',
  订单: '订单',
  关于APP: '关于APP',
  昵称: '昵称',
  手机号码: '手机号码',
  平台ID: '平台ID',
  设置昵称: '设置昵称',
  不超过20个字符: '不超过20个字符',
  昵称长度: '昵称长度4-20个字符之间',
  取消: '取消',
  退出登录: '退出登录',
  储值金: '储值金',
  话费收益: '话费收益',
  余额收益: '余额收益',
  收益详情: '收益详情',
  储值明细: '储值明细',
  团队情况: '团队情况',
  收益项目: '收益项目',
  收益时间: '收益时间',
  收益类型: '收益类型',
  金额: '金额',
  储值好友: '储值好友',
  储值金额: '储值金额',
  好友昵称: '好友昵称',
  没有更多了: '没有更多了',
  我的余额: '我的余额',
  提现: '提现',
  余额明细: '余额明细',
  确定添加: '确定添加',
  卡号: '卡号',
  有效期: '有效期',
  卡片上的姓名: '卡片上的姓名',
  选择有效期: '选择有效期',
  添加银行卡: '添加银行卡',
  已购套餐: '已购套餐',
  已赎回套餐: '已赎回套餐',
  收益明细: '收益明细',
  套餐名称: '套餐名称',
  购买时间: '购买时间',
  收益间隔: '收益间隔',
  收益: '收益',
  赎回时间: '赎回时间',
  赎回确认: '赎回确认',
  话费: '话费',
  储值: '储值',
  储值2:'储值',
  订单状态: '订单状态',
  订单编号: '订单编号',
  商品名称: '商品名称',
  首单优惠: '首单优惠',
  下单时间: '下单时间',
  加载中: '加载中',
  去使用: '去使用',
  已使用: '已使用',
  分享: '分享',
  可使用: '可使用',
  已过期: '已过期',
  限购一次: '限购一次',
  储值每满: '储值每满',
  交易成功: '交易成功',
  交易取消: '交易取消',
  待支付: '待支付',
  未提交: '未提交',
  提交成功: '提交成功',
  提交失败: '提交失败',
  充值成功: '充值成功',
  充值失败: '充值失败',
  已支付: '已支付',
  已赎回: '已赎回',
  储值详情: '储值详情',
  期数: '期数',
  周期: '周期',
  周期开始时间: '周期开始时间',
  周期结束时间: '周期结束时间',
  仅限一次: '仅限一次',
  月: '月',
  天: '天',
  小时: '小时',
  分: '分',
  赎回确认信息: '申请赎回后,储值将在#释放,释放后返回到余额!',
  储值时间: '储值时间',
  是否登录查看我的储值: '是否登录查看我的储值?',
  是否登录分享给好友: '是否登录分享给好友?',
  是否登录查看团队储值: '是否登录查看团队储值?',
  已赠送: '已赠送',
  复制分享地址成功: '复制分享地址成功',
  复制分享地址失败: '复制分享地址失败',
  登录后可自动领取好友分享的优惠券: '登录后可自动领取好友分享的优惠券',
  赎回:'赎回',
  选择银行:'选择银行',
  银行:'银行',
  请选择银行:'请选择银行',
  请选择银行卡:'请选择银行卡',
  请输入金额:'请输入金额',
  提现到银行卡:'提现到银行卡',
  请添加银行卡:'请添加银行卡',
  提现金额:'提现金额',
  全部:'全部',
  是否要退出登录状态:'是否要退出登录状态?',
  记录:'记录',
  提现记录:'提现记录',
  待处理:'待处理',
  处理成功:'处理成功',
  取消:'取消',
  不使用:'不使用',
  一重好礼:'一重好礼：任意好友首次充值，你获得#$直充券*1',
  二重好礼:'二重好礼：团队储值总额度达标，你获得直充券+余额',
  售价:'售价',
  等待支付:'等待支付',
  支付成功:'支付成功',
  支付失败:'支付失败',
  公告:'公告',
  继续购买:'继续购买',
  邀请:'邀请',
  复制链接:'复制链接',
  复制链接成功:'复制链接成功',
  复制链接失败:'复制链接失败',
  消息:'消息',
  关于我们:'关于我们',
  获得:'获得',
  打开链接领取优惠券:'打开链接领取优惠券',
  领取优惠券:'领取优惠券',
  朋友分享的优惠券:'##分享的优惠券',
  是否登录领取优惠券:'是否登录领取优惠券',
  我的优惠券: '我的优惠券',
  正常:'正常',
  赎回申请:'赎回申请',
  取消赎回:'取消赎回',
}