import { createI18n } from 'vue-i18n'
import { Locale } from 'vant'
import common from '@/assets/js/common'
import cn from './cn'
import en from './en'
import hk from './hk'

import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import zhHK from 'vant/lib/locale/lang/zh-HK'
var lang = localStorage['lang'] ? localStorage['lang'] : 'en';

//设置vant当前使用的语言
switch (lang) {
  case 'en':
    Locale.use('en-US', enUS);
    break;
  case 'cn':
    Locale.use('zh-CN', zhCN);
    break;
  case 'hk':
    Locale.use('zh-HK', zhHK);
    break;
}
//设置i18n当前使用的语言
const i18n = new createI18n({
  locale: lang,
  allowComposition: true,
  globalInjection: true,
  messages: { cn, en, hk }
})

//语言切换
export function SetLanguage (lang) {
  //切换i18n使用的语言
  this.$root.$i18n.locale = lang;
  localStorage['lang'] = lang;

  //切换vant使用的语言
  switch (lang) {
    case 'en':
      Locale.use('en-US', enUS);
      break;
    case 'cn':
      Locale.use('zh-CN', zhCN);
      break;
    case 'hk':
      Locale.use('zh-HK', zhHK);
      break;
  }
}
export default i18n