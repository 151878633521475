export default {
  not_login: '未登錄', //未登录
  network_error: '網絡錯誤',//网络错误
  language: "選擇語言",
  nodata: '沒有數據',
  loading_completed: '没有更多了',//没有更多了
  title_home: '首頁',//首页
  title_about: '關於我們',//关于我们
  title_plan: '生態規劃',//规划
  title_partner: '合作夥伴',//合作伙伴
  title_agent: '成為代理',//代理
  title_user: '個人中心',//我的
  title_language: '語言設置',//语言设置
  title_record: '記錄',//记录
  title_rule: '遊戲規則',//游戏规则
  title_login: '登錄',//登录
  title_register: '註冊',//注册
  title_forget: '忘記密碼',//忘记密码
  mobile_tabbar_home: '首頁',//首页
  mobile_tabbar_plan: '規劃',//规划
  mobile_tabbar_partner: '合作夥伴',//合作伙伴
  mobile_tabbar_agent: '代理',//代理
  mobile_tabbar_user: '我的',//我的
  copy_success: '復製成功',//复制成功
  copy_error: '復製失敗',//复制失败
  web_name: '{sitename}',//哈希趣投
  copy: '一鍵復製',//一键复制
  bet: '一鍵投註',//一键投注
  bet_title: '請輸入投註金額',//请输入投注金额
  bet_botton: '確認投註',//确认投注
  solgan: "全球首款{sitename}挖礦遊戲應用",//全球首款哈希趣投挖矿游戏应用
  new_qu_order_tip: '最新投注訂單',//最新趣投订单
  bet_between: '投註區間:{min}~{max} U',//投注区间:{min}~{max} U
  starting_throw: '起投',//起投
  odds: '賠率',//赔率
  view_all: '查看更多',//查看更多
  qt_address: '投注地址',//趣投地址
  first_page: '首頁',//首页
  last_page: '尾頁',//尾页
  previous_page: '上一頁',//上一页
  next_page: '下一頁',//下一页
  page_page: '第{page}頁',//第{page}页
  page: '頁',//页
  total_record: '共{totalnum}條記錄',//共{totalnum}条记录
  rule: {
    limit_min_tip: '{limit_min}U 起投',//{limit_min}U 起投
    odds_tip: '{odds} 賠率',//{odds}赔率
    quota_tip: '投注額度',//趣投额度
    game_rules_name: '查看規則',//游戏规则
    winning_case_name: '中獎案例',//中奖案例
    order_column1: '遊戲名称',//趣投游戏
    order_column2: '投注金额',//趣投金额A
    order_column3: '區塊哈希',//区块哈希
    order_column4: '是否中獎',//是否中奖
    order_column5: '投注時間',//趣投时间
  },
  rule_ppp: {
    name: '哈希碰碰碰',//哈希碰碰碰
    solgan: '數字碰字母',//数字碰字母
    bsc_contract_address_tip: 'BSC鏈趣投碰碰碰合約地址',//BSC链趣投碰碰碰合约地址
    play_tip: '根據區塊鏈錢包地址轉賬自動生成的區塊哈希值後兩位數，進行智能合約自動識別中獎。',//根据区块链钱包地址转账自动生成的区块哈希值后两位数，进行智能合约自动识别中奖。
    game_rules_tip1: '區塊哈希值後兩位數作為開獎結果：',//区块哈希值后两位数作为开奖结果：
    game_rules_tip2: '區塊哈希值後兩位數為：',//区块哈希值后两位数为：
    game_rules_tip3: '數字+字母，字母加數字',//数字+字母，字母加数字
    game_rules_tip4: '【1a，2b，3c，4d】或【a9，b8，c7，d6】',//【1a，2b，3c，4d】或【a9，b8，c7，d6】
    game_rules_tip5: '表示中獎',//表示中奖
    game_rules_tip6: '區塊哈希值後兩位數為：',//区块哈希值后两位数为：
    game_rules_tip7: '數字+數字，字母加字母',//数字+数字，字母加字母
    game_rules_tip8: '【13，25，38，41】或【av，bf，cw，dm】',//【13，25，38，41】或【av，bf，cw，dm】
    game_rules_tip9: "表示未中獎",//表示未中奖
    winning_case_tip1: '您的轉賬金額為：',//您的转账金额为：
    winning_case_tip2: '1000USDT',//1000USDT
    winning_case_tip3: '該筆轉賬的區塊哈希為: 000*****57aba',//该笔转账的区块哈希为: 000*****57aba
    winning_case_tip4: '區塊後兩位數為',//区块后两位数为
    winning_case_tip5: '字母+字母',//字母+字母
    winning_case_tip6: ",則表示不中獎",//,则表示不中奖
    winning_case_tip7: '該筆轉賬的區塊哈希為:000*****56a0g1',//该笔转账的区块哈希为:000*****56a0g1
    winning_case_tip8: '區塊後兩位數為',//区块后两位数为
    winning_case_tip9: '字母+數字',//字母+数字
    winning_case_tip10: ',則表示中獎',//,则表示中奖
    winning_case_tip11: '連本帶利',//连本带利
    winning_case_tip12: '1000+950=1950USDT',//
  },
  rule_znn: {
    name: '哈希抓牛牛',//哈希抓牛牛
    solgan: '趣味鬥牛點',//趣味斗牛点
    bsc_contract_address_tip: 'BSC鏈趣投抓牛牛合約地址',//BSC链趣投抓牛牛合约地址
    play_tip: '由區塊哈希值的後五位數字作為開獎數字左邊3位數字代表莊家，右邊3位數字代表閑家。字母代表整10，1~9相加為牛 1~牛牛',//由区块哈希值的后五位数字作为开奖数字左边3位数字代表庄家，右边3位数字代表闲家。字母代表整10，1~9相加为牛 1~牛牛
    game_rules_tip1: '1:向遊戲地址轉入資金 ',// 1:向游戏地址转入资金 
    game_rules_tip2: '{limit_min}-{limit_max}USDT ',// 10-2000USDT 
    game_rules_tip3: '2:投註金額是轉賬金額的十分之一',//2:投注金额是转账金额的十分之一
    game_rules_tip4: ' 3:如投註 10USDT，則需要轉賬 ',// 3:如投注 10USDT，则需要转账 
    game_rules_tip5: ' 100USDT ',// 100USDT 
    game_rules_tip6: '4:轉賬後根據區塊哈希值最後5個數(如:0000***57ab6)為牌面',// 4:转账后根据区块哈希值最后5个数(如:0000***57ab6)为牌面
    game_rules_tip7: '5:左邊',// 5:左边
    game_rules_tip8: " 3個數 57a 為莊家點數牛二， ",// 3个数 57a 为庄家点数牛二， 
    game_rules_tip9: ' 右邊 ',//  右边 
    game_rules_tip10: '  3 個數 ab6 位閑家點數牛六   ',// 3 个数 ab6 位闲家点数牛六  
    game_rules_tip11: ' 閑家贏獲取六倍收益，以此類推牛幾贏就獲得幾倍收益。 ',// 闲家赢获取六倍收益，以此类推牛几赢就获得几倍收益。 
    game_rules_tip12: '6:閑家贏自動扣除5%手續費。',//6:闲家赢自动扣除5%手续费。
    game_rules_tip13: '7:牛1~牛2莊閑同點莊贏，其他同點扣千分之一手續費退還本金。',//7:牛1~牛2庄闲同点庄赢，其他同点扣千分之一手续费退还本金。
    game_rules_tip14: '8:低於最低金額或高於最高金額（無效投註）',//8:低于最低金额或高于最高金额（无效投注）
    game_rules_tip15: '9:僅支持整數的投註，小數點部分自動忽略',//9:仅支持整数的投注，小数点部分自动忽略


    winning_case_tip1: '您的轉賬金額為：  ',//您的转账金额为： 
    winning_case_tip2: '1000USDT ',//1000USDT 
    winning_case_tip3: ' 該筆轉賬的區塊哈希值為：  ',//该笔转账的区块哈希值为： 
    winning_case_tip4: 'hud0jh*****57aba ',//hud0jh*****57aba 
    winning_case_tip5: '莊家開獎號碼是：57a',//庄家开奖号码是：57a
    winning_case_tip6: '5+7+10=22 牛二',//5+7+10=22 牛二
    winning_case_tip7: '閑家開獎號碼是：aba',//闲家开奖号码是：aba
    winning_case_tip8: '10+10+10=30 牛牛 ',//10+10+10=30 牛牛 
    winning_case_tip9: '閑家下註 1000USDT 牛牛，贏十倍 1000USDT ',//闲家下注 1000USDT 牛牛，赢十倍 1000USDT 
    winning_case_tip10: '閑贏抽 5%，盈 1000U 抽 50U ',//闲赢抽 5%，盈 1000U 抽 50U 
    winning_case_tip11: '連本帶利  ',//连本带利 
    winning_case_tip12: '1000U+1000U-50U=1950U ',//1000U+1000U-50U=1950U 
  },
  rule_slh: {
    name: '哈希雙龍會',//哈希双龙会
    solgan: '猜大小單雙',//猜大小单双
    bsc_contract_address_tip: 'BSC鏈趣投雙龍會合約地址',//BSC链趣投双龙会合约地址
    play_tip: '使用冷錢包BEP20-USTD轉賬隨機生成的區塊哈希值，最後一位數字為開獎結果',//使用冷钱包BEP20-USTD转账随机生成的区块哈希值，最后一位数字为开奖结果
    game_rules_tip1: ' 0,1,2,3,4 為小  5,6,7,8,9 為大 ',//0,1,2,3,4 为小  5,6,7,8,9 为大 
    game_rules_tip2: ' 1,3,5,7,9 為單  0,2,4,6,8 為雙 ',//1,3,5,7,9 为单  0,2,4,6,8 为双 
    game_rules_tip3: "舉例：以玩家轉賬金額的個位數（0，9，1，8）為例 0代表小、9代表大、 1代表單、8代表雙",//举例：以玩家转账金额的个位数（0，9，1，8）为例 0代表小、9代表大、 1代表单、8代表双
    game_rules_tip4: '例子：',//例子：
    game_rules_tip5: ' 1000USDT 個位數是 0 代表押註  ',//1000USDT 个位数是 0 代表押注 
    game_rules_tip6: ' 【小】  ',//【小】 
    game_rules_tip7: ' 1009USDT 個位數是 9 代表押註  ',//1009USDT 个位数是 9 代表押注 
    game_rules_tip8: ' 【大】 ',//【大】 
    game_rules_tip9: ' 1001USDT 個位數是 1 代表押註  ',//1001USDT 个位数是 1 代表押注 
    game_rules_tip10: ' 【單】  ',//【单】 
    game_rules_tip11: ' 1008USDT 個位數是 8 代表押註  ',//1008USDT 个位数是 8 代表押注 
    game_rules_tip12: ' 【雙】  ',//【双】 
    game_rules_tip13: '說明：',//说明：
    game_rules_tip14: "'區塊哈希值最後一位數一共有4️種結果,除（0、1、8、9）以外轉賬個位數是其他數字表示無效！押註扣除千分之一手續費退回投註金額。',",//'区块哈希值最后一位数一共有4️种结果,除（0、1、8、9）以外转账个位数是其他数字表示无效！押注扣除千分之一手续费退回投注金额。',
    winning_case_tip1: ' 您的轉賬金額為：  ',//您的转账金额为： 
    winning_case_tip2: '1000USDT ',//1000USDT 
    winning_case_tip3: ' 表示押註  ',//表示押注  
    winning_case_tip4: ' 【小】 ',//【小】 
    winning_case_tip5: '轉賬後生成區塊哈希值為',//转账后生成区块哈希值为
    winning_case_tip6: '0*9e8d0； 9e8d1； 9e2da； 9ef3d； 94abb；',//0*9e8d0； 9e8d1； 9e2da； 9ef3d； 94abb；
    winning_case_tip7: ' 最後一位數字是0，1，2，3，4為 ',//最后一位数字是0，1，2，3，4为 
    winning_case_tip8: ' 【小】',//【小】 
    winning_case_tip9: '  結果：中獎；系統立即向您返獎： ',//结果：中奖；系统立即向您返奖：
    winning_case_tip10: '1950USDT  ',//1950USDT  
  },
  rule_zxh: {
    name: '哈希莊閑合',//哈希庄闲合
    solgan: '莊閑大比拼',//庄闲大比拼
    bsc_contract_address_tip: 'BSC鏈趣投莊閑合合約地址 ',//BSC链趣投庄闲合合约地址
    play_tip: '根據區塊鏈錢包轉賬哈希值後5位數作為開獎號碼，左邊兩位數代表莊家號碼，右邊兩位數代表閑家號碼。',//根据区块链钱包转账哈希值后5位数作为开奖号码，左边两位数代表庄家号码，右边两位数代表闲家号码。
    game_rules_tip1: '以區塊哈希值後五位數取左右【四位】為開獎結果，莊閑各取兩位相加（0~9）比大小。',//以区块哈希值后五位数取左右【四位】为开奖结果，庄闲各取两位相加（0~9）比大小。
    game_rules_tip2: '字母表表0，0點最小，9點最大。',//字母表表0，0点最小，9点最大。
    game_rules_tip3: '左邊兩位數為莊牌，右邊兩位為閑牌，相加同點為合',//左边两位数为庄牌，右边两位为闲牌，相加同点为合
    game_rules_tip4: ' 轉賬個位數為1代表壓莊，轉賬個位數為2代表壓閑，轉賬個位數為3代表壓合 ',// 转账个位数为1代表压庄，转账个位数为2代表压闲，转账个位数为3代表压合 
    game_rules_tip5: ' 【1莊2閑3合】',// 【1庄2闲3合】 
    winning_case_tip1: ' 案例一 ',// 案例一 
    winning_case_tip2: '玩家投註 1001USDT 壓莊，區塊哈希值為 00***212',//玩家投注 1001USDT 压庄，区块哈希值为 00***212
    winning_case_tip3: '  左邊第 4，第 5 位是 ',//左边第 4，第 5 位是
    winning_case_tip4: ' a b 為 0+0=莊 0 點  ',//a b 为 0+0=庄 0 点 
    winning_case_tip5: ' 右邊第 1，第 2 位是  ',//右边第 1，第 2 位是 
    winning_case_tip6: ' 1 2 為 1+2=閑 3 點 ',//1 2 为 1+2=闲 3 点 
    winning_case_tip7: ' 莊 0 點輸，閑 3 點贏 ',//庄 0 点输，闲 3 点赢 
    winning_case_tip8: ' 投註 1001U 閑贏不返獎  ',//投注 1001U 闲赢不返奖 
    winning_case_tip9: ' 案例二  ',//案例二  
    winning_case_tip10: '玩家投註 1002USDT 壓閑，區塊哈希值是 00***4ba69',//玩家投注 1002USDT 压闲，区块哈希值是 00***4ba69
    winning_case_tip11: ' 左邊第 4，第 5 位是 ',//左边第 4，第 5 位是 
    winning_case_tip12: ' 4 b 為 4+0=莊 4 點  ',//4 b 为 4+0=庄 4 点 
    winning_case_tip13: ' 右邊第 1，第 2 位是  ',//右边第 1，第 2 位是 
    winning_case_tip14: ' 6 9為 6+9=閑 5 點  ',//6 9为 6+9=闲 5 点 
    winning_case_tip15: ' 莊 4 點輸，閑 5 點贏  ',//庄 4 点输，闲 5 点赢 
    winning_case_tip16: ' 投註 1002U 壓閑贏 1953.9U  ',//投注 1002U 压闲赢 1953.9U 
    winning_case_tip17: ' 案例三  ',//案例三 
    winning_case_tip18: '玩家投註 1003USDT 壓合，區塊哈希值是 00***ac255',//玩家投注 1003USDT 压合，区块哈希值是 00***ac255
    winning_case_tip19: ' 左邊第 4，第 5 位是  ',//左边第 4，第 5 位是 
    winning_case_tip20: ' a c 為0+0=莊 0 點  ',//a c 为0+0=庄 0 点 
    winning_case_tip21: ' 右邊第 1，第 2 位是  ',//右边第 1，第 2 位是 
    winning_case_tip22: ' 5 5 為 5+5=閑 0 點  ',//5 5 为 5+5=闲 0 点 
    winning_case_tip23: ' 莊 0 點，閑 0 點，莊閑同點合  ',//庄 0 点，闲 0 点，庄闲同点合 
    winning_case_tip24: ' 投註 1003U 壓合，返獎 8 倍 8024USDT  ',//投注 1003U 压合，返奖 8 倍 8024USDT 
  },
  record_type: {
    order: '投注訂單',//趣投订单
    income: '投注收益',//趣投收益
    invited: '邀請人數',//邀请人数
    teams: '團隊人數',//团队人数
    airdrop: '空投權重',//空投权重
    promotion: '推廣收益',//推广收益
  },
  login: {
    username_tip: '請輸入賬號',//请输入账号
    password_tip: '請輸入密碼',//请输入密码
    loading: '登錄中...',//登录中...
    load: '登錄',//登录
    register_tip1: "沒有帳戶? ",//没有帐户？
    register_tip2: '立即註冊',//立即注册
    load_success: '登錄成功',//登录成功
  },
  register: {
    username_tip: '請輸入賬號',//请输入账号
    imgcode_tip: '請輸入圖形驗證碼',//请输入图形验证码
    emailcode_tip: '請輸入郵箱驗證碼',//请输入邮箱验证码
    sendemailcode: '發送驗證碼',//发送验证码
    password_tip: '請輸入密碼',//请输入密码
    password2_tip: '請再次輸入密碼',//请再次输入密码
    registering: '註冊中...',//注册中...
    register: '註冊',//注册
    register_success: '註冊成功',//注册成功
    wallet_address: '請輸入TRC20地址',//请输入TRC20地址
    invition: '請輸入邀請碼,沒有可不填寫',//请输入邀请码,没有可不填写
    login_tip1: '已有賬號? ',//已有账号?
    login_tip2: '立即登錄',//立即登录
    password_error: '兩次密碼不一致',//两次密码不一致
    password_error2: '密碼長度最少6位',//密码长度最少6位
  },
  home: {
    banner_title: '{sitename}',//哈希趣投
    banner_desc: "全球最大的智慧合約雜湊數位生態",//全球最大的智能合约哈希数字生态
    hyyh: '活躍用戶',//活跃用户
    hyljfj: '累計返獎',//累计返奖
    hyzqz: '總權重',//总权重
    qtlc: '{sitename}流程',//趣投流程
    qtlc_one_tip1: '第一步',//第一步
    qtlc_one_tip2: '開通去中心化',//开通去中心化
    // qtlc_one_tip3: '幣安智慧鏈錢包',//币安智能链钱包
    qtlc_one_tip3: '波場智能鏈錢包',//波场智能链钱包
    qtlc_two_tip1: '第二步',//第二步
    qtlc_two_tip2: '注册交易所購USDT',//注册交易所购USDT
    // qtlc_two_tip3: 'BEP20提幣到錢包',//BEP20提币到钱包
    qtlc_two_tip3: 'TRC20提幣到錢包',//TRC20提币到钱包
    qtlc_three_tip1: '第三步',//第三步
    qtlc_three_tip2: '從錢包內轉帳到',//从钱包内转账到
    qtlc_three_tip3: '合約地址贏取獎勵',//合约地址赢取奖励
    qtlc_column1: ' 平臺 ',//平台
    qtlc_column2: '網址',//网址
    qtlc_column3: '查看教程',//查看教程
    qtlc_step1: '1、複製{sitename}遊戲合約投注地址',//1、复制趣投游戏合约投注地址
    qtlc_step2: '2、只能用錢包轉帳TRC20~USDT到投注地址',//2、只能用钱包转账BEP20~USDT到投注地址
    qtlc_step3: '3、自動匹對區塊雜湊獲得{sitename}獎勵',//3、自动匹对区块哈希获得趣投奖励
    qtlx_title: '{sitename}遊戲',//趣投游戏
    qtlx_view_rules: ' 查看規則 ',//查看规则
    qtrecord_tip: '{sitename}記錄',//趣投记录
    qtrecord_time: '時間',//时间
    qtrecord_hash: '哈希值',//哈希值
    qtrecord_bonus: '獎金',//奖金
    chose_title: '為什麼選擇我們',//为什么选择我们
    chose_setp1_tip1: '雙重體驗',//双重体验
    chose_setp1_tip2: '兩種投註方式任你選擇：',//两种投注方式任你选择：
    chose_setp1_tip3: '「一鍵復製」冷錢包實時投註，',//1，“一键复制”冷钱包实时投注，
    chose_setp1_tip4: '「一鍵投註」官網在線投註。',//2，“一键投注”官网在线投注。
    chose_setp2_tip1: '公開透明:',//公开透明:
    chose_setp2_tip2: '任何人任何時候都可以在區塊鏈瀏覽器查詢任意記錄。',//任何人任何时候都可以在区块链浏览器查询任意记录。
    chose_setp3_tip1: '公平可信:',//公平可信:
    chose_setp3_tip2: '哈希值基於區塊鏈技術散列函數隨機生成，絕對不存在人為幹預。',//哈希值基于区块链技术散列函数随机生成，绝对不存在人为干预。
    chose_setp4_tip1: '安全簡單:',//安全简单:
    chose_setp4_tip2: '只需一個錢包即可參與，無需註冊，無需認證，無需下載，無凍卡風險，無需充值存款，隱私安全有保證。',//只需一个钱包即可参与，无需注册，无需认证，无需下载，无冻卡风险，无需充值存款，隐私安全有保证。
    chose_setp5_tip1: '玩法靈活:',//玩法灵活:
    chose_setp5_tip2: '多種玩法、N種量化策略、24小時可參與、不看K線，不受市場漲跌影響，完全隨心所欲。',//多种趣投玩法、N种量化策略、24小时可参与、不看K线，不受市场涨跌影响，完全随心所欲。
    chose_setp6_tip1: '玩法靈活:',//玩法灵活:
    chose_setp6_tip2: '多種玩法、N種量化策略、24小時可參與、不看K線，不受市場漲跌影響，完全隨心所欲。',//多种趣投玩法、N种量化策略、24小时可参与、不看K线，不受市场涨跌影响，完全随心所欲。
    question_title: '常見問題',//常见问题
    question_setp1_tip1: '什麽是GameFi ？',//什么是GameFi ？
    question_setp1_tip2: "通俗來說，GameFi就是Game+Defi，在遊戲中加入Defi模型，將 DeFi 遊戲化。通過有趣的競猜模式、增加用戶/玩家互動性，提高趣味性、實現在遊戲中邊玩邊賺P2E。",//通俗来说，GameFi就是Game+Defi，在游戏中加入Defi模型，将 DeFi 游戏化。通过有趣的竞猜模式、增加用户/玩家互动性，提高趣味性、实现在游戏中边玩边赚P2E。
    question_setp2_tip1: '什麽是哈希遊戲(Hash game)？',//什么是哈希游戏(Hash game)？
    question_setp2_tip2: '哈希遊戲由智能合約根據轉賬生成的區塊哈希，自動獎勵玩家。比其他GameFi遊戲更公平，沒有復雜的道具、機製設定。',//哈希游戏由智能合约根据转账生成的区块哈希，自动奖励玩家。比其他GameFi游戏更公平，没有复杂的道具、机制设定。
    question_setp3_tip1: '什麽是區塊哈希(Block hash)?',//什么是区块哈希(Block hash)?
    question_setp3_tip2: '區塊哈希在轉賬時由散列函數Hash()自動隨機生成，具有唯一，透明，且不可篡改，不可預知。點擊轉賬記錄對應的區塊數字即可查看。',//区块哈希在转账时由散列函数Hash()自动随机生成，具有唯一，透明，且不可篡改，不可预知。点击转账记录对应的区块数字即可查看。
    question_setp4_tip1: '為什麽玩家會連續中/不中獎？',//为什么玩家会连续中/不中奖？
    question_setp4_tip2: '每筆轉賬區塊哈希都是隨機生成的。每次中與不中都是獨立隨機事件，跟運氣有關。連續中獎或不中獎都是小概率事件。可參考「倍投玩法」來獲得穩定收益。',//每笔转账区块哈希都是随机生成的。每次中与不中都是独立随机事件，跟运气有关。连续中奖或不中奖都是小概率事件。可参考“倍投玩法”来获得稳定收益。
    connect: '連接錢包',//连接钱包
    connect_wallet: '請連接錢包',//请连接钱包
    unlock_wallet: '請解鎖您的錢包',//请解锁您的钱包
    install_tronlink: '請安裝TronLink插件',//请安装TronLink插件,
    amount_invalid: '金額無效',//金额无效
    amount_limit_exceed: '投注金額超出限額',//投注金额超出限额
    open_in_wallet: '請在錢包瀏覽器中打開此網站',//请在钱包浏览器中打开此网站
  },
  plan: {
    title: '生態發展六個階段',//生态发展六个阶段
    sub_title: '期待與您一起開拓美好未來！',//期待与您一起开拓美好未来！
    step1_title: '第一階段',//第一阶段
    step1_content1: '{sitename}官網上線',//Hello.Fun官网上线
    step1_content2: '{sitename}生態白皮書發布',//Hello.Fun生态白皮书发布
    step1_content3: '{sitename}GameFi上線',//哈希趣投GameFi上线
    step2_title: ' 第二階段',//第二阶段
    step2_content1: '{sitename} Dapp發布遊戲增加',//哈希趣投Dapp发布游戏增加
    step2_content2: '「{sitename} 挖礦」權重機製發起DAO提案',//“趣投挖矿”权重机制发起DAO提案
    step2_content3: '啟動{sitename} DAO全球自治社區',//启动Hello DAO全球自治社区
    step3_title: '第三階段',//第三阶段
    step3_content1: '鑄造發行首批{sitename}特權NFT',//铸造发行首批Hello特权NFT
    step3_content2: '發行首個Token - HLO及經濟模型',//发行首个Token - HLO及经济模型
    step3_content3: '解密及實施「{sitename}挖礦」',//解密及实施“趣投挖矿”
    step4_title: '第四階段',//第四阶段
    step4_content1: '{sitename} DAO社區用戶突破50萬',//Hello DAO社区用户突破50万
    step4_content2: '測試上線自有Defi應用{sitename} Swap',//测试上线自有Defi应用Hello Swap
    step4_content3: '發行第二個Token - Hi及經濟模型',//发行第二个Token - Hi及经济模型
    step5_title: '第五階段',//第五阶段
    step5_content1: '{sitename} DAO社區用戶突破200萬',//Hello DAO社区用户突破200万
    step5_content2: '啟動SocialFi計劃及應用開發',//启动SocialFi计划及应用开发
    step5_content3: '打造數字娛樂 GameFi+Defi+SocialFi(GDS)',//打造数字娱乐 GameFi+Defi+SocialFi(GDS)
    step5_content4: '測試{sitename} Metaverse技術底層及基礎設施',//测试Hello Metaverse技术底层及基础设施
    step6_title: '第六階段',//第六阶段
    step6_content1: '啟動 {sitename} Chain 基礎鏈設計(數字娛樂公鏈)',//启动 Hello Chain 基础链设计(数字娱乐公链)
    step6_content2: '發行母體 Token - {sitename}及經濟模型(極致通縮)',//发行母体 Token - Hello及经济模型(极致通缩)
    step6_content3: '{sitename} Metaverse正式發布，徹底去中心化自治',//Heelo Metaverse正式发布，彻底去中心化自治
  },
  partner: {
    title: '生態合作',//生态合作
    ys_title: 'Cooperation advantage',//合作优势
    ys_step1_tip: '合作優勢',//1、公开透明：
    ys_step1_content: '任何人任何時候都可以在區塊鏈瀏覽器查詢任意記錄',//任何人任何时候都可以在区块链浏览器查询任意记录
    ys_step2_tip: '2、公平可信：',//2、公平可信：
    ys_step2_content: '哈希值基於區塊鏈技術散列函數隨機生成，絕對不存在人為幹預',//哈希值基于区块链技术散列函数随机生成，绝对不存在人为干预
    ys_step3_tip: '3、安全簡單：',//3、安全简单：
    ys_step3_content: '只需一個錢包即可參與，無需註冊，無需認證，無需下載，隱私安全有保證',//只需一个钱包即可参与，无需注册，无需认证，无需下载，隐私安全有保证
    ys_step4_tip: '4、玩法靈活：',//4、玩法灵活：
    ys_step4_content: '多種玩法，N 種量化策略、24 小時可參與、不看 K 線，不受市場漲跌影響，完全隨心所欲',//多种趣投玩法，N 种量化策略、24 小时可参与、不看 K 线，不受市场涨跌影响，完全随心所欲
    ys_step5_tip: '5、智能合約：',//5、智能合约：
    ys_step5_content: 'BEP-20/USDT 參與，無凍卡風險，無需充值存款，簡單轉賬操作，智能合約自動返獎',//BEP-20/USDT 参与，无冻卡风险，无需充值存款，简单转账操作，智能合约自动返奖
    tg_title: '合作推廣',//合作推广
    tg_setp1: '按六個階段發展需要推動全球營銷計劃',//按六个阶段发展需要推动全球营销计划
    tg_setp2: '*CMC&amp;CG、Twitter 熱搜、TG、頻道',//*CMC&amp;CG、Twitter 热搜、TG、频道
    tg_setp3: '*主流錢包&amp;交易所Dapp推薦位',//*主流钱包&amp;交易所Dapp推荐位
    tg_setp4: '*Dextool 排名、Coinzilla + poocoi 廣告',//*Dextool 排名、Coinzilla + poocoi 广告
    tg_setp5: '*Wechat 社群、telegram 社區、地推、沙龍',//*Wechat 社群、telegram 社区、地推、沙龙
    partner: '{sitename} 生態合作夥伴'//HELLO FUN 生态合作伙伴
  },
  agent: {
    ring: '環',//环
    title: '加入生態夥伴',//加入生态伙伴
    sub_title: '期待與您一起開拓美好未來!',//期待与您一起开拓美好未来!
    yqurl_tip: '邀請鏈接',//邀请链接
    setp1_tip: '第1階段主推應用「{sitename}」，已開發上線四款Hash遊戲：',//第1阶段主推应用“哈希趣投”，已开发上线四款Hash游戏：
    bonus_tip1: '您將獲得豐厚的獎勵：',//您将获得丰厚的奖励：
    bonus_tip2: '加入 {sitename} DAO，贈送權益 NFT',//加入 Hello DAO，赠送权益 NFT
    bonus_tip3: '獲得「{sitename}挖礦」獎勵的 Token',//获得“趣投挖矿”奖励的 HLO Token
    bonus_tip4: '15*多代分傭，天天領取 USDT',//15*多代分佣，天天领取 Usdt
    explain_title: '級分傭舉例說推廣收益明',//级分佣举例说推广收益明
    explain_label: '推廣收益',//推广收益
    explain_desc: '假設每人分享三個用戶，每人每天投注1000U計算，您所獲得的推廣收益。',//假设每人分享三个用户，每人每天趣投1000U计算，您所获得的推广收益。
    table_tip: '一天收益預算',//一天收益预算
    table_column1: '環數',//环数
    table_column2: '比例',//比例
    table_column3: '人數',//人数
    table_column4: '當天流水(u)',//当天流水(u)
    table_column5: '當天分傭(u)',//当天分佣(u)
    table_column6: '當天累計(u)',//当天累计(u)
    kaohe_tip: '考核標準',//考核标准
    kaohe_desc: '推廣用戶初始級別為1星用戶，推廣即獲得第一環用戶投注流水的{rebate1}%分紅。每推薦一位用戶累計投注滿{upgrade_amount}USDT，自動往上升一級，以此類推。推薦10個用戶投注滿{upgrade_amount}USDT將自動升級為10星用戶，享受十環內用戶產生的投注流水收益。',
    qt_wkkt_tip: '「{sitename}挖礦」及空投權重說明',//“趣投挖矿”及空投权重说明
    qt_wk_tip: '{sitename}挖礦：',//趣投挖矿：
    qt_wk_desc1: '「{sitename}挖礦」是{sitename}生態推出的貢獻挖礦產幣機製，全球獨創！',//“趣投挖矿”是hallo.fun生态推出的贡献挖矿产币机制，全球独创！
    qt_wk_desc2: '用戶/玩家在參與{sitename}遊戲趣投的同時將會獲得{sitename}生態內發行的代幣獎勵。',//用户/玩家在参与Hello.fun游戏趣投的同时将会获得Hello生态内发行的代币奖励。
    qt_wk_desc3: " 在代幣經濟模型發布之前，因Token尚未正式發行，因此前期將會以權重的形式累計體現。{sitename}將會以玩家權重的數量給予空投代幣，讓用戶一邊享受{sitename}樂趣的同時，還能獲得{sitename}生態的GameFi、SocialFi、Defi等各種Token獎勵，多一份未來收益，邊玩邊賺，多維度獲利。",
    qt_kt_tip: '空投權重',//空投权重
    qt_kt_desc1: '空投權重是「{sitename}挖礦」機製的核心指標，是用戶/玩家/夥伴對{sitename}生態作出貢獻的量化指標，也是未來生態Token空投時的首要參考系數。 ',//空投权重是“趣投挖矿”机制的核心指标，是用户/玩家/伙伴对Hello生态作出贡献的量化指标，也是未来生态Token空投时的首要参考系数。 
    qt_kt_desc2: '空投權重發放說明',//空投权重发放说明
    qt_kt_desc3: '1、玩家每投注1U將自動發放0.01的空投權重，自動累計。',//1、玩家每趣投1U将自动发放0.01的空投权重，自动累计。
    qt_kt_desc4: '2、推客每產生1U的推廣獎勵將獲得0.1的空投權重，自動累計。',//2、推客每产生1U的推广奖励将获得0.1的空投权重，自动累计。
    yqbf: '歡迎加入 / 一起暴富'//欢迎加入 / 一起暴富
  },
  user: {
    become_agent: '成為代理',//成为代理
    interest_grade: '投注等級',//趣投等级
    promotion_level: '推廣等級',//推广等级
    order: '投注訂單',//趣投订单
    income: '投注收益',//趣投收益
    invited_number: '邀請人數',//邀请人数
    number_teams: '團隊人數',//团队人数
    airdrop_weight: '空投權重',//空投权重
    promotion_income: '推廣收益',//推广收益
    receive: '領取',//领取
    connect_first: '請連接錢包登錄',//请连接钱包登录
    record: {
      order_column1: '遊戲名稱',//趣投游戏
      order_column2: '投註金額',//趣投金额
      order_column3: '區塊哈希',//区块哈希
      order_column4: '是否中獎',//是否中奖
      order_column5: '投註時間',//趣投时间
      income_column1: '遊戲名稱',//趣投游戏
      income_column2: '投註金額',//趣投金额
      income_column3: '區塊哈希',//区块哈希
      income_column4: '獎勵金額',//奖励金额
      income_column5: '投註時間',//趣投时间
      invited_column1: '用戶地址',//用户地址
      invited_column2: '投註金額',//投注金额
      invited_column3: '投註等級',//趣投等级
      invited_column4: '註冊時間',//注册时间
      teams_column1: '用戶地址',//用户地址
      teams_column2: '投註金額',//投注金额
      teams_column3: '推廣等級',//推广等级
      teams_column4: '註冊時間',//注册时间
      airdrop_column1: '權重信息',//权重信息
      airdrop_column2: '權重數量',//权重数量
      airdrop_column3: '累計權重',//累计权重
      airdrop_column4: '獎勵時間',//奖励时间
      promotion_column1: '用戶地址',//用户地址
      promotion_column2: '投註金額',//趣投金额
      promotion_column3: '獎勵金額',//奖励金额
      promotion_column4: '獎勵時間',//奖励时间
      winning: '中獎',//中奖
      not_win: '未中獎',//未中奖
      no_result: '還沒有開獎',//还没有开奖
    },
    not_perfection: '未完善',//未完善
    complete_information: '完善信息',//完善信息
    user_info: '用戶信息'//用户信息
  },
  about: {
    title_about: '關於我們',//关于我们
    about_desc1: '{sitename}旨在打造下一代 WEB3.0數字娛樂生態標桿，涵蓋五大熱點領域 ',//Hello.Fun旨在打造下一代 WEB3.0数字娱乐生态标杆，涵盖五大热点领域 
    about_desc2: 'GameFi、NFT、DeFi、SocialFi、Metaverse, ',//GameFi、NFT、DeFi、SocialFi、Metaverse, 
    about_desc3: '目前規劃了六個發展階段，以「{sitename}挖礦」為共識，極速構建一個完全自組織(DAO)的「嗨樂元宇宙」（{sitename}-Mets）！',//目前规划了六个发展阶段，以“趣投挖矿”为共识，极速构建一个完全自组织(DAO)的“嗨乐元宇宙”（Hello-Mets）！
    block1_tip: 'Hello,World!',//Hello,World!
    block1_desc: '1972年，貝爾實驗室成員布萊恩·柯林漢采用B語言撰寫了第一個Hello World的示範程序。',//1972年，贝尔实验室成员布莱恩·柯林汉采用B语言撰写了第一个Hello World的示范程序。
    block2_tip: 'Hello,BlockChain!',//Hello,BlockChain!
    block2_desc: '2009年1月3日，中本聰在芬蘭赫爾辛基的一個服務器上挖出了比特幣的第一個區塊block #0——創世區塊(Genesis Block)，並獲得了首批「挖礦」獎勵——50個比特幣。',//2009年1月3日，中本聪在芬兰赫尔辛基的一个服务器上挖出了比特币的第一个区块block #0——创世区块(Genesis Block)，并获得了首批“挖矿”奖励——50个比特币。
    block3_tip: 'Hello,WEB3.0!',//Hello,WEB3.0!
    block3_desc1: '2022 年 02 月 22 日',//2022 年 02 月 22 日
    block3_desc2: '（完美對稱日），Hello,WEB3.0數字娛樂生態正式創建，於3月3日正式上線測試GameFi階段首款遊戲應用——{sitename}。',//（完美对称日），Hello,WEB3.0数字娱乐生态正式创建，于3月3日正式上线测试GameFi阶段首款游戏应用——哈希趣投。
    hash_tip1: '首款應用——{sitename}',//首款应用——哈希趣投
    hash_tip2: '「{sitename}」——是{sitename} 區塊鏈生態在GameFi階段推出的首款應用Dapp，其使命是推行「{sitename}挖礦」機製，吸納百萬創世居民。',//“哈希趣投HashFun”——是Hello.Fun 区块链生态在GameFi阶段推出的首款应用Dapp，其使命是推行“趣投挖矿”机制，吸纳百万创世居民。
    app_tip1: '四款{sitename}應用',//四款哈希趣投应用
    hilites_tip1: '趣味亮點：',//趣味亮点：
    hilites_left_tip1: '哈希函數：100%透明，公平公正',//哈希函数：100%透明，公平公正
    hilites_left_tip2: '智能合約：BNB 主鏈、可信安全',//智能合约：BNB 主链、可信安全
    hilites_left_tip3: '流暢體驗：24H 在線，秒投秒返',//流畅体验：24H 在线，秒投秒返
    hilites_left_tip4: '極簡操作：無需下載，無需登陸',//极简操作：无需下载，无需登陆
    hilites_left_tip5: '高中獎率： 1.95(1U 暴富)',//高中奖率： 1.95(1U 暴富)
    hilites_right_tip1: '趣投挖礦：P2E(邊玩邊賺)',//趣投挖矿：P2E(边玩边赚)
    hilites_right_tip2: '玩家福利：V1-V8(升級送 U)',//玩家福利：V1-V8(升级送 U)
    hilites_right_tip3: '鎖粉裂變：1*-15*(多代分傭)',//锁粉裂变：1*-15*(多代分佣)
    hilites_right_tip4: '生態價值：U(獎勵)+DAO(團隊)',//生态价值：U(奖励)+DAO(团队)
    hilites_right_tip5: '+NFT(特權)+Token(挖礦)',//+NFT(特权)+Token(挖矿)
    footer_tip1: "{sitename}從哈希遊戲出發，而不止步於此。通過「公開透明、公平公正」的{sitename}，建立強大的{sitename} DAO社區，聚集人才和用戶，分階段實現戰略部署，完成",//Hello.Fun从哈希游戏出发，而不止步于此。通过“公开透明、公平公正”的哈希趣投，建立强大的Hello DAO社区，聚集人才和用户，分阶段实现战略部署，完成
    footer_tip2: 'GameFi、NFT、DAO、DeFi、SocialFi、Metaverse',//GameFi、NFT、DAO、DeFi、SocialFi、Metaverse
    footer_tip3: ' 落地應用，帶領玩家、用戶、社區貢獻者邁向下一代區塊鏈生態。 ',//落地应用，带领玩家、用户、社区贡献者迈向下一代区块链生态。 
  },
  dialog: {
    loading: '加載中...',//加载中...
    confirm_title: '提示',//提示
  },
  start: {
    desc: '看見美好的世界'//看见美好的世界
  },
  userinfo: {
    username: '用戶名',//用户名
    address: '地址',//地址
    agent_code: '邀請碼',//邀请码
    agent_url: '邀請鏈接',//邀请链接
    grade: '推廣等級',//推广等级
    password: '登錄密碼',//登录密码
    click_settings: '點擊設置',//点击设置
    update_pwd_title: '修改登錄密碼',//修改登录密码
    update_pwd_tip1: '原登錄密碼',//原登录密码
    update_pwd_tip2: '新登陸密碼',//新登陆密码
    update_pwd_tip3: '確認密碼',//确认密码
    update_pwd_placeholder1: '請輸入原登錄密碼',//请输入原登录密码
    update_pwd_placeholder2: '請輸入新登錄密碼',//请输入新登录密码
    update_pwd_placeholder3: '請確認登錄密碼',//请确认登录密码
    submit: '提交',//提交
    submiting: '提交中...',//提交中...
    update_success: '修改成功',//修改成功
    login_out: '退出',//退出
    save: '保存',//保存
    save_success: '保存成功',//保存成功
    complete_userinfo: '請完善用戶信息',//请完善用户信息
  }
}