import { createRouter, createWebHashHistory } from "vue-router"
import common from "@/assets/js/common"
// import i18n from "@/i18n"
// import i18n from 'vue-i18n'
import router_mobile from "./mobile";
import router_pc from "./pc";

const router = createRouter({
  history: createWebHashHistory(),//采用这种路由模式
  // history: createWebHistory(),   createWebHistory
  // routes: common.IsMobile() ? router_mobile : router_pc
  routes: router_mobile 

})

//全局路由守卫
// router.beforeEach((to, from, next) => {
//   if (from.name != 'login' && from.name != 'register') {
//     localStorage['frompage'] = from.fullPath;
//   }
//   else {
//     localStorage.removeItem('frompage');
//   }
//   if (to.meta.requiresAuth && !localStorage['token']) {
//     next('/login');
//   }
//   else {
//     next();
//   }
// });


export default router;