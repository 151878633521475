<template>
<van-empty :description="tip" >
  <template #image>
    <div class="textcenter">
      <img src="../assets/img/empty.png" style="width:16rem;" />
    </div>
              
  </template>
</van-empty>
</template>

<script>
export default {
  name: 'MobileEmpty',
   setup() {
   
  },
  props: {
    description:{
      type:String
    }
  },
  data() {
    return {
      tip:this.description?this.description:this.$t('暂无信息')
    }
  },

}
</script>


<style scoped>
:deep .van-empty__image{
  width: var(--van-empty-image-size);
    height: auto;
}
</style>