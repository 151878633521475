const router_mobile = [
  {
    path: '/language',
    name: 'language',
    component: () => import('@/views/mobile/Language.vue'),
    meta: {
      title: '语言'
    }
  },
  
  {
    path: '/:agent_code?',//默认显示的页面
    name: 'home',
    component: () => import('@/views/mobile/home/Home.vue'),
    meta: {
      title: '话费充值'
    },
    props: true
  },
  {
    path: '/recharge/:operatorid/:mobile',//充值
    name: 'recharge',
    component: () => import('@/views/mobile/home/Recharge.vue'),
    meta: {
      title: '充值'
    },
    props: true
  },
  {
    path: '/option/:operatorid',//选择金额
    name: 'option',
    component: () => import('@/views/mobile/home/Option.vue'),
    meta: {
      title: '充值'
    },
    props: true
  },
  {
    path: '/writemobile/:operatorid/:amountid',//填写手机号码
    name: 'writemobile',
    component: () => import('@/views/mobile/home/WriteMobile.vue'),
    meta: {
      title: '充值'
    },
    props: true
  },
  {
    path: '/pay/:operatorid/:amountid/:mobile',//充值支付页面
    name: 'pay',
    component: () => import('@/views/mobile/home/Pay.vue'),
    meta: {
      title: '安全支付'
    },
    props: true
  },
  {
    path: '/addcard',//绑定信用卡页面
    name: 'addcard',
    component: () => import('@/views/mobile/home/AddCard.vue'),
    meta: {
      title: '添加信用卡'
    },
    props: true
  },
  {
    path: '/payresult',//使用余额充值支付结果页面
    name: 'payresult',
    component: () => import('@/views/mobile/home/PayResult.vue'),
    meta: {
      title: '支付结果'
    },
    props: true
  },
  {
    path: '/payresultother',//使用信用卡充值支付结果页面
    name: 'payresultother',
    component: () => import('@/views/mobile/home/PayResultOther.vue'),
    meta: {
      title: '支付结果'
    },
    props: true
  },
  {
    path: '/free',
    name: 'free',
    component: () => import('@/views/mobile/free/Free.vue'),
    meta: {
      title: '免费充值'
    }
  },
  {
    path: '/paycz/:id',//购买储值券的支付页面
    name: 'paycz',
    component: () => import('@/views/mobile/free/PayCz.vue'),
    meta: {
      title: '安全支付'
    },
    props: true
  },
  {
    path: '/gift',
    name: 'gift',
    component: () => import('@/views/mobile/gift/Gift.vue'),
    meta: {
      title: '邀请有礼'
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/mobile/user/User.vue'),
    meta: {
      title: '个人中心',
      // requiresAuth: true
    }
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('@/views/mobile/user/UserInfo.vue'),
    meta: {
      title: '个人资料',
    }
  },
  {
    path: '/groupcz',
    name: 'groupcz',
    component: () => import('@/views/mobile/user/GroupCz.vue'),
    meta: {
      title: '团队储值',
    }
  },
  {
    path: '/balance',
    name: 'balance',
    component: () => import('@/views/mobile/user/Balance.vue'),
    meta: {
      title: '我的余额',
    }
  },
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import('@/views/mobile/user/Withdrawal.vue'),
    meta: {
      title: '提现',
    }
  },
  {
    path: '/withdrawalrecord',
    name: 'withdrawalrecord',
    component: () => import('@/views/mobile/user/WithdrawalRecord.vue'),
    meta: {
      title: '提现记录',
    }
  },
  {
    path: '/addbankcard',
    name: 'addbankcard',
    component: () => import('@/views/mobile/user/AddBankCard.vue'),
    meta: {
      title: '添加银行卡'
    },
    props: true
  },
  {
    path: '/mycz',
    name: 'mycz',
    component: () => import('@/views/mobile/user/MyCz.vue'),
    meta: {
      title: '我的储值',
    }
  },
  {
    path: '/orderrecord',
    name: 'orderrecord',
    component: () => import('@/views/mobile/user/OrderRecord.vue'),
    meta: {
      title: '订单',
    }
  },
  {
    path: '/orderdetails/:id/',
    name: 'orderdetails',
    component: () => import('@/views/mobile/user/OrderDetails.vue'),//话费订单详情页面
    meta: {
      title: '订单详情'
    },
    props: true
  },
  {
    path: '/plandetails/:id/',
    name: 'plandetails',
    component: () => import('@/views/mobile/user/PlanDetails.vue'),//储值详情页面
    meta: {
      title: '储值详情'
    },
    props: true
  },
  {
    path: '/mycoupon',
    name: 'mycoupon',
    component: () => import('@/views/mobile/user/MyCoupon.vue'),
    meta: {
      title: '我的优惠券'
    },
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/mobile/public/Login.vue'),
    meta: {
      title: '登录'
    },
  },
  {
    path: '/logincode',
    name: 'logincode',
    component: () => import('@/views/mobile/public/LoginCode.vue'),
    meta: {
      title: '验证码登录'
    }
  },
  {
    path: '/logincodesend/:type/:mobile',
    name: 'logincodesend',
    component: () => import('@/views/mobile/public/LoginCodeSend.vue'),
    meta: {
      title: '发送验证码'
    },
    props: true
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/mobile/public/Register.vue'),
    meta: {
      title: '手机注册'
    }
  },
  { //注册第二步：填写密码
    path: '/registerpwd/:mobile/:smscode',
    name: 'registerpwd',
    component: () => import('@/views/mobile/public/RegisterPwd.vue'),
    meta: {
      title: '设置密码'
    },
    props: true
  },
  {
    path: '/find',
    name: 'find',
    component: () => import('@/views/mobile/public/Find.vue'),
    meta: {
      title: '找回密码'
    }
  },
  {//找回密码第二步：填写新密码
    path: '/findpwd/:mobile/:smscode',
    name: 'findpwd',
    component: () => import('@/views/mobile/public/FindPwd.vue'),
    meta: {
      title: '找回密码'
    },
    props: true
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/mobile/public/Agreement.vue'),
    meta: {
      title: '用户协议'
    }
  },
  {
    path: '/notice/:id/',
    name: 'notice',
    component: () => import('@/views/mobile/home/Notice.vue'),//公告详情页面
    meta: {
      title: '公告'
    },
    props: true
  },
  {
    path: '/messgerecord',
    name: 'messgerecord',
    component: () => import('@/views/mobile/user/MessageRecord.vue'),//站内消息记录
    meta: {
      title: '消息'
    },
    props: true
  },
  {
    path: '/messagedetails/:id',
    name: 'messagedetails',
    component: () => import('@/views/mobile/user/MessageDetails.vue'),//站内消息详情
    meta: {
      title: '消息'
    },
    props: true
  },
  {
    path: '/getcoupons',
    name: 'getcoupons',
    component: () => import('@/views/mobile/public/GetCoupons.vue'),
    meta: {
      title: '领取优惠券'
    },
    props: true
  },
]
export default router_mobile