<template>
<div class="van-sticky--fixed">
  <van-nav-bar
        :border="false"
        style="background: transparent;"
        :right-text="$t('language')"
        @click-right="$router.push('/language')"
      >
        <template #title>
          <img :src="this.$Store.state.InitData.site.logo_mobile_path" style="width:12rem; height: 3rem" />
        </template> 
         <template #left>
        </template>
      </van-nav-bar>
</div>      
</template>

<script>
export default {
  name: 'MobileHead',
   setup() {
   
  },
  props: [],
  data() {
    return {
     
    }
  },
  created(){
    
  },
  methods:{
    
  }
}
</script>


<style scoped>
.account {
    padding: 0rem 0.6rem;
    background: #5acbc2;
    border-radius: 2rem;
    color: #fff;
    font-size: 1.2rem;
}

</style>