import router from "@/router";
import axios from 'axios'
import { Toast } from 'vant'
import qs from 'qs'
import store from '@/store'
import i18n from '@/i18n'
import dialog from './dialog'

//创建实例
var instance = axios.create({
  baseURL: store.state.Host,
  timeout: 10000,//设置超时时间10秒
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    //'Token':store.state.Token?store.state.Token:'',//此处拿不到缓存里最新的token值，所以在请求拦截里设置
    // 'Content-Type': 'application/json',
    // 'X-Requested-With': 'XMLHttpRequest'
  }
});

/*取消请求*/
var source = axios.CancelToken.source();
//添加请求拦截
instance.interceptors.request.use(
  config => {
    config.cancelToken = source.token;
    config.data = config.data || {};

    if (store.state.UserInfo && config.data.address == null) {
      config.data.address = store.state.UserInfo.address;
    }
    //根据请求的API地址进行拦截
    // if (config.url.indexOf('Member') >= 0 && !store.state.UserInfo) {
    //   config.cancelToken = source.token;//取消请求
    //   source.cancel({ code: -1, msg: i18n.global.t('not_login') });//设置取消请求的返回信息
    // }
    
    //

    //在此处设置请求头token参数，确保拿到缓存里最新的token值
    config.headers['Token']=store.state.Token?store.state.Token:'';
    
    config.data = qs.stringify(config.data);//将传递的json数据转为form data,后台才可以接收到参数

    var lang = 'lang=' + (localStorage['lang'] || 'en-us');
    if (config.method == 'post') {
      if (config.url.indexOf('?') > 0) {
        config.url += '&' + lang;
      }
      else {
        config.url += '?' + lang;
      }
    }

    if (config.method == 'get') {
      if (config.url.indexOf('?') > 0) {
        config.url += '&' + config.data + '&' + lang;
      }
      else {
        config.url += '?' + config.data + '&' + lang;
      }
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//添加响应拦截
// instance.interceptors.response.use(
//   response => {
//     if (response.data.ret == 401) {
//       source = axios.CancelToken.source();
//     }
//     return response;
//   },
//   error => {
//     // console.log(JSON.stringify(error));
//     //请求拦截里(instance.interceptors.request.use)如果拦截到并取消请求会跳转到此处,
//     //例如在用户中心页面请求后台数据时如果没有获取到钱包地址被拦截后，可以在此处实现路由跳转到首页并提示用户需要链接授权钱包地址
//     if (error.name && error.name == 'CanceledError' && error.code == 'ERR_CANCELED') {
//       gohome();
//     }
//     else {
//       Toast(i18n.global.t('network_error'));
//     }
//   }
// );

//跳转到首页自动执行链接钱包功能
const gohome = (() => {
  dialog.alert(i18n.global.t('home.connect'), () => {
    router.replace('/');
  });
});

export default instance