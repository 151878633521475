<template>
<!-- <div class="van-sticky--fixed"> -->
<div class="w100">
      <van-nav-bar
        :border="false"
         left-arrow
        @click-left="leftClick()"
      >
      <template #left>
          <img v-if="type==1" src="../assets/img/leftjtb.png" style="width:1.8rem;height: 1.8rem;"/>
          <img v-else src="../assets/img/leftjt.png" style="width:1.8rem;height: 1.8rem;"/>
        </template>
        <template #title>
          <span v-if="type==1" class="colorf">{{ title }}</span>
          <span v-else>{{ title }}</span>
        </template>
      </van-nav-bar>
    </div> 
</template>

<script>
export default {
  name: 'MobileNavBar',
   setup() {
   
  },
  props: {
    //通过该属性显示不同的样式
    type:{
      type:Number
    },
    //通过该属性实现路由跳转的地址
    routerurl:{
      type:String,
      default:null
    },
    //是否显示title
    showTitle:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
        title:this.showTitle==true?this.$t(this.$route.meta.title):''
    }
  },
  methods:{
    //点击左侧按钮
    leftClick(){
      if(this.routerurl)
      {
        this.$router.push(this.routerurl);
      }
      else{
        this.$router.go(-1)
      }
    }
  }
}
</script>


<style scoped>
.van-sticky--fixed,.van-nav-bar{
  background: transparent !important;
}

</style>