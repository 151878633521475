export default {
  暂无数据: 'Empty',
  暂无信息: 'Empty',
  提示: 'Tips',
  语言: 'Language',
  参数无效: 'Invalid parameters',
  话费充值: 'Recharge',
  免费充值: 'Free',
  邀请有礼: 'Invitation',
  个人中心: 'User',
  选择语言: 'Choose language',
  邮箱:"Email",
  邮箱格式错误:"Email format error",
  请输入邮箱: 'Please enter email',
  请输入手机号: 'Please enter phone number',
  请输入密码: 'Please enter password',
  忘记: 'forget?',
  我已阅读并同意: 'I have read and agree',
  与: 'and ',
  用户协议: 'User Agreement',
  隐私政策: 'Privacy Policy',
  验证码登录: 'Verification code login',
  注册账户: 'Register',
  登录: 'Sign in',
  登录中: 'Sign in...',
  其他登录方式: 'Other login methods',
  登录成功: 'Login success',
  请勾选已阅读: 'Please check the box that has been read',
  密码登录: 'Password login',
  发送验证码: 'Send verification code',
  手机号码无效: 'Invalid phone number',
  验证码发送至: 'Send verification code to',
  手机验证码: 'SMS code',
  发送成功: 'Sending success',
  获取短信验证码: 'Get SMS verification code',

  手机注册: 'Phone register',
  设置密码: 'Set password',
  请输入x位密码: 'Please enter a 6-20 digit password',
  下一步: 'Next step',
  手机号无效: 'Invalid phone number',
  验证码无效: 'Invalid verification code',
  注册成功: 'Register success',

  找回密码: 'Retrieve password',
  参加储值计划获得免费充值卷: 'Get a free recharge coupon',
  前往: '',
  开始充值: 'Recharge',
  充值: 'Recharge',
  充值2:'',
  号码: 'Phone number',
  更换运营商: 'Change of operator',
  折: '%',
  属于运营商: 'Belongs to the operator',
  充值号码: 'Recharge phone number',
  充值号码2: 'Recharge phone',
  运营商无效: 'Invalid operator',
  金额无效: 'Invalid amount',
  继续充值: 'Continue to recharge',
  安全支付: 'Secure payment',
  订单详情: 'Order details',
  商品价格: 'Commodity price',
  优惠券: 'Coupon',
  实付金额: 'Payment amount',
  支付方式: 'Payment method',
  总计: 'Total',
  立即充值: 'Pay now',
  获取用户信息失败: 'Failed to obtain user information',
  余额不足: 'Sorry, your credit is running low',
  最小充值: 'Minimum recharge',
  无可用优惠券: 'No coupons',
  支付结果: 'Payment results',
  付款成功: 'Payment successful',
  付款失败: 'Payment failed',
  查看订单: 'View orders',
  返回首页: 'Return home',
  邀请好友免费获券: 'Inviting friends to receive free coupons',
  前往邀请: 'invite',
  好友人数: 'friends count',
  海量余额: 'More balance',
  请输入卡号: 'Please enter card number',
  请输入姓名: 'Please enter your name',
  添加信用卡: 'Add credit card',
  填写您的信用卡信息: 'Fill in your # information',
  确定: 'Ok',
  请选择: 'Please select',
  请填写卡号: 'Please fill in the card number',
  请选择有效期: 'Please select an expiration date',
  请填写CVC: 'Please fill in CVC/CVV',
  请填写姓名: 'Please fill in your name',
  请选择支付方式: 'Please select payment method',
  立即支付: 'Pay Now',
  储值计划: 'StoragePlan',
  我的储值: 'MyRecord',
  储值说明: 'Help',
  购买: 'Buy',
  领券: 'Coupon',
  团队储值: 'GroupRecord',
  奖励规则: 'RewardRules',
  剩余: 'surplus',
  剩余2: '',
  邀请好友赚直充券加余额: 'Invite friends to earn direct recharge coupons and balance',
  仅话费充值可用: 'Only available for phone recharge',
  直充券: 'Recharge Coupon',
  余额: 'Balance',
  立即邀请: 'Invite Now',
  二维码: 'QR code',
  个人资料: 'Personal information',
  立即登录: 'Log in now',
  余额可提现: 'Balance',
  个人储值: 'MyStorage',
  团队储值2: 'GroupStorage',
  订单: 'Order',
  关于APP: 'About the APP',
  昵称: 'Nickname',
  手机号码: 'Phone number',
  平台ID: 'Platform ID',
  设置昵称: 'Set Nickname ',
  不超过20个字符: 'No more than 20 characters',
  昵称长度: 'Between 4 and 20 characters',
  取消: 'Cancel',
  退出登录: 'Sign out',
  储值金: 'Storage',
  话费收益: 'Bills',
  余额收益: 'Balance',
  收益详情: 'Revenue',
  储值明细: 'Storage',
  团队情况: 'Group',
  收益项目: 'Revenue items',
  收益时间: 'Revenue time',
  收益类型: 'Revenue type',
  金额: 'Amount',
  储值好友: 'Friends',
  储值金额: 'StorageAmount',
  好友昵称: 'Friend nickname',
  没有更多了: 'No more',
  我的余额: 'My balance',
  提现: 'Withdrawal',
  余额明细: 'Balance details',
  确定添加: 'Confirm addition',
  卡号: 'Card number',
  有效期: 'Term of validity',
  卡片上的姓名: 'Name on the card',
  选择有效期: 'Select validity period',
  添加银行卡: 'Add bank card',
  已购套餐: 'BuyRecord',
  已赎回套餐: 'Redeem',
  收益明细: 'Revenue',
  套餐名称: 'Package Name',
  购买时间: 'Buy time',
  收益间隔: 'Revenue cycle',
  收益: 'Revenue',
  赎回时间: 'Redeem time',
  赎回确认: 'Confirm Redeem',
  话费: 'Bill',
  储值: 'Storage',
  储值2:'',
  订单状态: 'Order status',
  订单编号: 'Order number',
  商品名称: 'Product Name',
  首单优惠: 'First order discount',
  下单时间: 'Order time',
  加载中: 'Loading',
  去使用: 'Go use',
  已使用: 'Used',
  分享: 'Share',
  可使用: 'Can be used',
  已过期: 'Expired',
  限购一次: 'Only once',
  储值每满: 'Store',
  交易成功: 'Successful trade',
  交易取消: 'Cancel trade',
  待支付: 'To be paid',
  未提交: 'Unsubmitted',
  提交成功: 'Submitted successfully',
  提交失败: 'Submission failed',
  充值成功: 'Recharged successfully',
  充值失败: 'Recharge failed',
  已支付: 'Paid',
  已赎回: 'Redeemed',
  储值详情: 'Storage details',
  期数: 'Number of periods',
  周期: 'Cycle',
  周期开始时间: 'Start time',
  周期结束时间: 'End time',
  仅限一次: 'Only once',
  月: 'month',
  天: 'day',
  小时: 'hour',
  分: 'minute',
  赎回确认信息: 'After applying for redemption, the stored value will be released in # and returned to the balance after release!',
  储值时间: 'Storage time',
  是否登录查看我的储值: 'Do you want to log in and view my storage record?',
  是否登录分享给好友: 'Do you want to log in and share with friends?',
  是否登录查看团队储值: 'Do you want to log in and view group storage record?',
  已赠送: 'Gifted',
  复制分享地址成功: 'Successfully copied the sharing address',
  复制分享地址失败: 'Copying sharing address failed',
  登录后可自动领取好友分享的优惠券: 'After logging in, you can automatically receive coupons shared by friends',
  赎回:'redeem',
  选择银行:'Select Bank',
  银行:'Bank',
  请选择银行:'Please select a bank',
  请选择银行卡:'Please select a bank card',
  请输入金额:'Please enter the amount',
  提现到银行卡:'Withdrawal to bank card',
  请添加银行卡:'Please add a bank card',
  提现金额:'Withdrawal amount',
  全部:'All',
  是否要退出登录状态:'Are you sure you want to exit?',
  记录:'Record',
  提现记录:'Withdrawal record',
  待处理:'Waiting',
  处理成功:'Successfully',
  取消:'Cancel',
  不使用:'not used',
  一重好礼:'One Good Gift: If any friend makes a first recharge, you will receive a #$ coupon * 1',
  二重好礼:'Two Good Gift: The total amount of team stored value meets the standard, and you will receive a coupon + balance',
  售价:'price',
  等待支付:'Waiting for payment',
  支付成功:'Payment successful',
  支付失败:'Payment failed',
  公告:'Notice',
  继续购买:'Continue Buy',
  邀请:'Invitation',
  复制链接:'Copy link',
  复制链接成功:'Copy link successfully',
  复制链接失败:'Copy link failed',
  消息:'Message',
  关于我们:'About Us',
  获得:'Get',
  打开链接领取优惠券:'Open the link to receive coupons',
  领取优惠券:'Get Coupons',
  朋友分享的优惠券:'Coupons shared by ##',
  是否登录领取优惠券:'Do you want to log in and receive coupons',
  我的优惠券: 'My coupon',
  正常:'Activated',
  赎回申请:'Redeem Apply',
  取消赎回:'Redeem Cancel',
}