import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import i18n, { SetLanguage } from './i18n'
import vant from 'vant'
import Clipboard from 'clipboard'
import common from '@/assets/js/common'
import dialog from '@/assets/js/dialog'
import 'vant/lib/index.css'
import '@/assets/css/style.css' //引入全局样式
import MobileHead from '@/components/MobileHead'
import MobileFoot from '@/components/MobileFoot'
import MobileEmpty from '@/components/MobileEmpty'
import MobileNavBar from '@/components/MobileNavBar'

const app = createApp(App)


//挂载全局属性
app.config.globalProperties.$Common = common;
app.config.globalProperties.$SetLanguage = SetLanguage;
app.config.globalProperties.$IsMobile = common.IsMobile();
app.config.globalProperties.$Store = store;
app.config.globalProperties.$Clipboard = Clipboard;
app.config.globalProperties.$Dialog=dialog;
app.component('MobileHead', MobileHead)
  .component('MobileFoot', MobileFoot)
  .component('MobileEmpty', MobileEmpty)
  .component('MobileNavBar', MobileNavBar)
app.use(i18n).use(router).use(store).use(vant).mount('#app');



//设置html的字号大小，作为rem的计算依据
const setHtmlFontSize = () => {
  const ismobile = common.IsMobile();
  app.config.globalProperties.$IsMobile = ismobile;

  const htmlDom = document.getElementsByTagName('html')[0];
  // if (ismobile === true) { //移动端
  //   require('@/assets/css/stylemobile.css');
  //   htmlDom.style.fontSize = '10px';
  // }
  // else { //非移动端
  //   require('@/assets/css/stylepc.css');
  //   htmlDom.style.fontSize = '10px';
  // }

  require('@/assets/css/stylemobile.css');
  htmlDom.style.fontSize = '10px';
};
// window.addEventListener('resize', setHtmlFontSize);
setHtmlFontSize();
