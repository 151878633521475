import { createStore } from 'vuex';
import urlobj from '@/assets/js/url'
export default createStore({
  state: {
    Host:urlobj.url+'/api/',//'http://localhost:120/api/',
    Url:urlobj.url,
    InitData: localStorage['InitData'] ? JSON.parse(localStorage['InitData']) : '',
    UserInfo: localStorage['UserInfo'] ? JSON.parse(localStorage['UserInfo']) : null,//用户信息
    AgentCode: localStorage['AgentCode'] ? localStorage['AgentCode'] : '',//别人的邀请码
    ShareToken:localStorage['ShareToken'] ? localStorage['ShareToken'] : '',//别人分享的优惠券token标识
    Token:localStorage['Token'] ? localStorage['Token'] : '',
    ToUrl:localStorage['ToUrl'] ? localStorage['ToUrl'] : '',//存放跳转的地址
  },
  mutations: {
    InitData (state, data) {
      state.InitData = data;
      localStorage['InitData'] = JSON.stringify(data);
    },
    UserInfo (state, data) {
      state.UserInfo = data;
      localStorage['UserInfo'] = JSON.stringify(data);
    },
   
    AgentCode (state, data) {
      state.AgentCode = data;
      localStorage['AgentCode'] = data;
    },
    ClearAgentCode(state){
      state.AgentCode='';
      localStorage.removeItem('AgentCode');
    },
    ShareToken (state, data) {
      state.ShareToken = data;
      localStorage['ShareToken'] = data;
    },
    ClearShareToken(state){
      state.ShareToken='';
      localStorage.removeItem('ShareToken');
    },
    ToUrl (state, data) {
      state.ToUrl = data;
      localStorage['ToUrl'] = data;
    },
    ClearToUrl(state){
      state.ToUrl='';
      localStorage.removeItem('ToUrl');
    },
    Token (state, data) {
      state.Token = data;
      localStorage['Token'] = data;
    },
    LoginOut(state){
      state.Token='';
      state.UserInfo=null;
      localStorage.removeItem('Token');
      localStorage.removeItem('UserInfo');

    }
  },
  actions: {
    InitData (Context, data) {
      Context.commit('InitData', data);
    },
    UserInfo (Context, data) {
      Context.commit('UserInfo', data);
    },
    Token (Context, data) {
      
      Context.commit('Token', data);
    },
    LoginOut(Context){
      Context.commit('LoginOut');
    },
    AgentCode (Context, data) {
      Context.commit('AgentCode', data);
    },
    ClearAgentCode(Context){
      Context.commit('ClearAgentCode');
    },
    ShareToken (Context, data) {
      Context.commit('ShareToken', data);
    },
    ClearShareToken(Context){
      Context.commit('ClearShareToken');
    },
    ToUrl (Context, data) {
      Context.commit('ToUrl', data);
    },
    ClearToUrl(Context){
      Context.commit('ClearToUrl');
    },
  },
  modules: {

  }
});