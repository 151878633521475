<template>
  <div class="flexrow footpanel" >
    <router-link to="/" class="flex1 flexrow align-item-center justify-content-center">
      <div class="flexcolumn align-item-center">
        <img class="navimg" :src="[$route.path=='/'?require('../assets/img/home_d.png'):require('../assets/img/home.png') ]"/>
        <span :class="$route.path=='/'?'navspanactive':'navspan'">{{$t('话费充值')}}</span>
      </div>
    </router-link>
    <router-link to="/free" class="flex1 flexrow align-item-center justify-content-center">
      <div class="flexcolumn align-item-center">
        <img class="navimg" :src="[$route.path=='/free'?require('../assets/img/free_d.png'):require('../assets/img/free.png') ]"/>
        <span :class="$route.path=='/free'?'navspanactive':'navspan'">{{$t('免费充值')}}</span>
      </div>
    </router-link>
    <router-link to="/gift" class="flex1 flexrow align-item-center justify-content-center">
      <div class="flexcolumn align-item-center">
        <img class="navimg" :src="[$route.path=='/gift'?require('../assets/img/gift_d.png'):require('../assets/img/gift.png') ]"/>
        <span :class="$route.path=='/gift'?'navspanactive':'navspan'">{{$t('邀请有礼')}}</span>
      </div>
    </router-link>
    <router-link to="/user" class="flex1 flexrow align-item-center justify-content-center">
      <div class="flexcolumn align-item-center" style="position: relative;">
        <template v-if="unreadCount>0">
          <div v-if="unreadCount<100" class="unreadpanel">{{ unreadCount }}</div>
          <div v-else class="unreadpanel"><span>...</span></div>
        </template>

        <img class="navimg" :src="[$route.path=='/user'?require('../assets/img/me_d.png'):require('../assets/img/me.png') ]"/>
        <span :class="$route.path=='/user'?'navspanactive':'navspan'">{{$t('个人中心')}}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
let that;
export default {
  name: 'MobileFoot',
   setup() {
   
  },
  props: [],
  data() {
    return {
      unreadCount:0//存放未读消息条数
    }
  },
  created(){
    that = this;
    that.getMessageCount();
  },
  methods: {
    //获取未读消息条数
    getMessageCount(){
      //已登录
      if (that.$store.state.UserInfo) {
        that.$Common.GetMessageCount((res) => {
          that.unreadCount=res.data;
        });
      }
    }
  }
}
</script>


<style scoped>
.navimg{
  width:2.2rem;
  height: 2.2rem;
}
.navspanactive{
  /* width: 4rem;
height: 1.7rem; */
font-family: PingFang SC, PingFang SC;
font-weight: 400;
font-size: 1rem;
color: #44379F;
line-height: 1.69rem;
text-align: center;
font-style: normal;
text-transform: none;
}
.navspan{
  /* width: 4rem;
height: 1.7rem; */
font-family: PingFang SC, PingFang SC;
font-weight: 400;
font-size: 1rem;
color: #8A91AA;
line-height: 1.69rem;
text-align: center;
font-style: normal;
text-transform: none;
}
.footpanel{
height: 5.3rem;
background: #FFFFFF;
box-shadow: 0rem -0.2rem 0.4rem 0rem rgba(146,149,154,0.2);
border-radius: 1.4rem 1.4rem 0rem 0rem;
}
.unreadpanel{
  display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    /* line-height: 1.5rem; */
    /* text-align: center; */
    border-radius: 50%;
    background: red;
    color: #fff;
    font-size: 1.1rem;
    top: -0.3rem;
    right: -0.6rem;
}
</style>