<template>
  <router-view/>
</template>

<script>
import config from '../package.json'
import i18n from '@/i18n'
export default {
  name: 'App',
  components: {
  },
  setup(){
  },
  created(){
    //获取初始化数据
    var href = window.location.href;
    
    var target_router = href.indexOf('#')>=0?href.split('#')[1]:'/';
    // this.$router.push('/start');
    this.$Common.GetInitData((res)=>{
      this.$router.push(target_router);
    })
    
    //全局路由守卫
    this.$router.beforeEach((to,from,next) => {
      //在此处实现页面title的国际化
      if (to.meta.title) {
        var title=to.meta.title;
        if(this.$Store.state.InitData)
        {
          var lan=this.$Common.GetLanguage();
          var sitename=this.$Store.state.InitData.site['sitename_'+lan];
          if(sitename)
          {
            document.title = sitename+'-'+i18n.global.t(title); 
          }
          else{
            document.title = i18n.global.t(title); 
          }
          // document.title = config.name+'-'+i18n.global.t(title); 
          
        }
        else{
          document.title = i18n.global.t(title); 
        }
      }
      else{
        document.title ='';
      }
     
      //没登陆用户并访问user页面跳转到登陆页面
      // if(!this.$store.state.UserInfo && to.name == 'user')
      // {
      //   next('/login');
      // }
      // else{
      //   next();
      // }
      next();
    });

    this.$router.afterEach((to, from) => {
      //console.log('路由跳转后触发事件,从：' + from.fullPath + '跳转到:' + to.fullPath);
    });
  }
}
</script>

<style>

</style>
