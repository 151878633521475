import router from "@/router";
import store from "@/store";
// import dialog from './dialog'
import axios from "./axios";
import i18n from '@/i18n';
const common = {

  IsMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (flag != null) {
      //移动端
      return true;
    } else {
      //非移动端:
      return false;
    }
  },
  //获取初始化数据
  GetInitData(callback) {
    const getData = ({ data }) => {
      if (data && data.data) {
        store.dispatch('InitData', data.data);
      }
      callback && callback(data);
    }
    axios.post('Pub/getInitData').then(getData);
  },
  //获取用户信息
  GetUserInfo(callback) {
    const getData = (res) => {
      if (res && res.data.ret == 0) {
        store.commit('UserInfo', res.data.data);
      }
      callback && callback(res.data);
    }
    axios.post('Member/getUserInfo',).then(getData).catch(err => { err });
  },
  //获取短信验证码
  GetSmsCode(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Pub/get_sms_code', params).then(getData).catch(err => { err });
  },
  //用户登录
  UserLogin(params, callback) {
    const getData = (res) => {
      if (res && res.data.ret == 0) {
        store.commit('UserInfo', res.data.data);
        store.commit('Token', res.data.token);
      }
      callback && callback(res.data);
    }
    axios.post('Pub/login', params).then(getData).catch(err => { err });
  },
  //用户短信验证码登录
  UserSmsCodeLogin(params, callback) {
    const getData = (res) => {
      if (res && res.data.ret == 0) {
        store.commit('UserInfo', res.data.data);
        store.commit('Token', res.data.token);
      }
      callback && callback(res.data);
    }
    axios.post('Pub/smscode_login', params).then(getData).catch(err => { err });
  },
  //用户注册
  UserRegister(params, callback) {
    const getData = (res) => {
      if (res && res.data.ret == 0) {
        store.commit('UserInfo', res.data.data);
        store.commit('Token', res.data.token);
      }
      callback && callback(res.data);
    }
    axios.post('Pub/regist', params).then(getData).catch(err => { err });
  },
  //用户找回密码
  UserFindPwd(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Pub/reset_pwd', params).then(getData).catch(err => { err });
  },
  GetSiteName() {
    var lan = this.GetLanguage();
    var sitename = '';
    if (store.state.InitData) {
      sitename = store.state.InitData.site['sitename_' + lan];
    }
    return sitename;
  },
  GetLogo() {
    var logourl = '';
    if (store.state.InitData) {
      logourl = store.state.Url + store.state.InitData.site['logo_square'];
    }
    return logourl;
  },
  GetLanguage() {
    return localStorage['lang'] ? localStorage['lang'] : 'en-us';
  },
  GetLanguageList() {
    return [
      { text: 'English', key: 'en-us', icon: require('../img/en-US.png') },
      { text: '简体中文', key: 'zh-cn', icon: require('../img/zh-CN.png') },
      // { text: '繁体中文 ', key: 'hk', icon: require('../img/zh-HK.png') },
    ];
  },

  LocalStorageSet(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },


  LocalStorageGet(key, defaultValue = '') {
    return JSON.parse(localStorage.getItem(key)) || defaultValue;
  },
  GetDateTime(times) {
    var date = new Date(times * 1000);
    var year = date.getFullYear();  // 获取完整的年份(4位,1970)
    var month = date.getMonth() + 1;  // 获取月份(0-11,0代表1月,用的时候记得加上1)
    if (month < 10) {
      month = "0" + month;
    }
    var day = date.getDate();  // 获取日(1-31)
    if (day < 10) {
      day = "0" + day;
    }
    var hours = date.getHours();  // 获取小时数(0-23)
    if (hours < 10) {
      hours = "0" + hours;
    }
    var minutes = date.getMinutes();  // 获取分钟数(0-59)
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    var seconds = date.getSeconds();  // 获取秒数(0-59)
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  },
  GetShortTime(times) {
    var date = new Date(times * 1000);
    var hours = date.getHours();  // 获取小时数(0-23)
    var minutes = date.getMinutes();  // 获取分钟数(0-59)
    var state = 'am';
    if (hours >= 12) {
      state = 'pm';
    }
    return hours + ':' + minutes + ' ' + state; //08:11 am  , 14:29 pm
  },




  UserUpdatePwd(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/updatePwd', params).then(getData).catch(err => { err });
  },
  UpdateUserinfo(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/updateUserinfo', params).then(getData).catch(err => { err });
  },
  //保留指定位数的小数
  FomatFloat(num, n) {
    var r = Math.round(num * Math.pow(10, n)) / Math.pow(10, n);
    return r;
  },
  //提交充值订单
  SubmitRechargeOrder(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/addOrder', params).then(getData).catch(err => { err });
  },
  //添加信用卡
  AddCreditCard(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/addCreditCard', params).then(getData).catch(err => { err });
  },
  //获取用户优惠券
  GetUserCoupon(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserCoupon', params).then(getData).catch(err => { err });
  },
  //获取储值计划列表
  GetPlans(callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Pub/getPlans').then(getData).catch(err => { err });
  },
  //根据id获取储值计划
  GetPlan(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Pub/getPlan', params).then(getData).catch(err => { err });
  },
  //购买储值
  BuyPlan(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/addPlan', params).then(getData).catch(err => { err });
  },
  //获取用户充值话费订单列表
  GetUserOrders(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserOrders', params).then(getData).catch(err => { err });
  },
  //获取用户充值话费订单详情
  GetUserOrder(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserOrder', params).then(getData).catch(err => { err });
  },

  //获取用户储值订单列表
  GetUserPlans(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserPlans', params).then(getData).catch(err => { err });
  },
  //获取用户储值订单详情
  GetUserPlan(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserPlan', params).then(getData).catch(err => { err });
  },
  //储值赎回
  RedeemPlan(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/redeemPlan', params).then(getData).catch(err => { err });
  },
  //取消储值赎回
  RedeemPlanCancel(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/redeemPlanCancel', params).then(getData).catch(err => { err });
  },
  //获取团队储值明细列表
  GetUserGroupPlanflows(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserGroupPlanflows', params).then(getData).catch(err => { err });
  },
  //我的储值收益明细列表
  GetUserPlanIncomes(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserPlanIncomes', params).then(getData).catch(err => { err });
  },
  //团队储值收益明细列表
  GetUserGroupPlanIncomes(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserGroupPlanIncomes', params).then(getData).catch(err => { err });
  },
  //团队情况
  GetUserGroupMembers(callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserGroupMembers').then(getData).catch(err => { err });
  },
  //余额明细列表
  GetUserFundflows(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserFundflows', params).then(getData).catch(err => { err });
  },
  //接收优惠券
  ReceiveCoupon(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/receiveCoupon', params).then(getData).catch(err => { err });
  },
  //银行列表
  GetBanks(callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getBanks').then(getData).catch(err => { err });
  },
  //添加银行卡
  AddUserBank(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/addUserBank', params).then(getData).catch(err => { err });
  },
  //提现
  AddUserWithdrawal(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/addUserWithdrawal', params).then(getData).catch(err => { err });
  },
  //提现记录
  GetWithdrawals(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getWithdrawals', params).then(getData).catch(err => { err });
  },
  //修改昵称
  ModifyNick(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/modifyNick', params).then(getData).catch(err => { err });
  },
  //获取用户协议
  GetAgreement(callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Pub/getAgreement').then(getData).catch(err => { err });
  },
  //用户充值状态
  GetUserOrderStatus(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getUserOrderStatus', params).then(getData).catch(err => { err });
  },
  //公告列表
  GetNotices(callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Pub/getNotices').then(getData).catch(err => { err });
  },
  //公告
  GetNotice(params, callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Pub/getNotice', params).then(getData).catch(err => { err });
  },
  //站内消息未读条数
  GetMessageCount(callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getMessageCount').then(getData).catch(err => { err });
  },
  //站内消息
  GetMessages(params,callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getMessages',params).then(getData).catch(err => { err });
  },
  //单条站内消息详情
  GetMessage(params,callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/getMessage',params).then(getData).catch(err => { err });
  },
  //站内消息设为已读
  SetMessageRead(params,callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/setMessageRead',params).then(getData).catch(err => { err });
  },
  //用户充值订单取消
  CancelUserOrder(params,callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Member/cancelUserOrder',params).then(getData).catch(err => { err });
  },
  //优惠券信息
  GetCouponInfo(params,callback) {
    const getData = (res) => {
      callback && callback(res.data);
    }
    axios.post('Pub/getCouponInfo',params).then(getData).catch(err => { err });
  },
  //处理话费订单的状态描述
  HandleOrderStatusCaption(row) {
    row['status_caption'] = '';
    row['status_code'] = 0;
    //0:待支付 1:已支付 2:取消支付
    if (row.status == 0) {
      row['status_caption'] = i18n.global.t('待支付');
      row['status_code'] = 0;//虚拟字符控制样式的颜色
    }
    else if(row.status==2)
    {
      row['status_caption'] = i18n.global.t('取消');
      row['status_code'] = 0;
    }
    else {
      switch (row.recharge_status) {
        case 0:
          row['status_caption'] = i18n.global.t('未提交');
          row['status_code'] = 0;
          break;
        case 1:
          row['status_caption'] = i18n.global.t('提交成功');
          row['status_code'] = 1;
          break;
        case 2:
          row['status_caption'] = i18n.global.t('提交失败');
          row['status_code'] = 0;
          break;
        case 3:
          row['status_caption'] = i18n.global.t('充值成功');
          row['status_code'] = 1;
          break;
        case 4:
          row['status_caption'] = i18n.global.t('充值失败');
          row['status_code'] = 0;
          break;
      }
    }
    return row;
  },
  //获取host地址
  GetHostUrl(){
    var url=window.location.protocol+"//"+window.location.host;
    return url;
  },
  //获取分享地址
  GetShareUrl(){
    var url=this.GetHostUrl();
    //已登录
    if (store.state.UserInfo) {
      url += "/#/" + store.state.UserInfo['agent_code'];//推荐码通过路由参数传递
    }

    return url;
  },
  
  //处理储值订单的状态描述
  HandlePlanStatusCaption(row) {
    row['status_caption'] = '';
    row['status_code'] = 0;
    //0:待支付 1:已支付
    if (row.status == 0) {
      row['status_caption'] = i18n.global.t('待支付');
      row['status_code'] = 0;//虚拟字符控制样式的颜色
    }
    else {
      row['status_caption'] = i18n.global.t('已支付');
      row['status_code'] = 1;//虚拟字符控制样式的颜色
    }
    //是否被赎回0-否，1-是
    if (row.is_redeem == 1) {
      row['status_caption'] = i18n.global.t('已赎回');
      row['status_code'] = 1;
    }
    return row;
  },
  //周期单位实现多语言
  HandleCyclUnit(unit){
    var result='';
    switch(unit)
    {
      case '天':
        result=i18n.global.t('天');
      break;
      case '月':
        result=i18n.global.t('月');
      break;
    }
    return result;
  },
  //根据开始时间计算距离结束时间还有多久
  CalcShEndTime(start_time,cycle,cycle_unit){
    
    var d=new Date(start_time);
    if(cycle_unit=='天')
    {
      d.setDate(d.getDate()+cycle);
    }
    else if(cycle_unit=='月'){
      d.setMonth(d.getMonth()+cycle);
    }
    var end_timestamp=d.valueOf();
    var current_timestamp=new Date().valueOf();//当前时间毫秒

    var result=this.FormatDuring(end_timestamp);
    var time=this.GetDateTime(end_timestamp/1000)
    return time;
  },
  //将毫秒数转换为天数、小时数、分钟数和秒数的计算方法，参数为毫秒数：
  FormatDuring(millisecond) {
    var days = parseInt(millisecond / (1000 * 60 * 60 * 24));
    var hours = parseInt((millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = parseInt((millisecond % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = (millisecond % (1000 * 60)) / 1000;
    return days +i18n.global.t('天') +' '+ hours + i18n.global.t('小时') +' '+ minutes + i18n.global.t('分');
    },
    //运营商号码段列表
    OperatorsMobileRangeList(){
      var res=[
        {name:"TNT",number:907,length:3,operatorid:1},
        {name:"TNT",number:909,length:3,operatorid:1},
        {name:"TNT",number:910,length:3,operatorid:1},
        {name:"TNT",number:912,length:3,operatorid:1},
        {name:"TNT",number:930,length:3,operatorid:1},
        {name:"TNT",number:938,length:3,operatorid:1},
        {name:"TNT",number:946,length:3,operatorid:1},
        {name:"TNT",number:948,length:3,operatorid:1},
        {name:"TNT",number:950,length:3,operatorid:1},
        {name:"Smart",number:908,length:3,operatorid:2},
        {name:"Smart",number:918,length:3,operatorid:2},
        {name:"Smart",number:919,length:3,operatorid:2},
        {name:"Smart",number:920,length:3,operatorid:2},
        {name:"Smart",number:921,length:3,operatorid:2},
        {name:"Smart",number:928,length:3,operatorid:2},
        {name:"Smart",number:929,length:3,operatorid:2},
        {name:"Smart",number:939,length:3,operatorid:2},
        {name:"Smart",number:946,length:3,operatorid:2},
        {name:"Smart",number:947,length:3,operatorid:2},
        {name:"Smart",number:949,length:3,operatorid:2},
        {name:"Smart",number:951,length:3,operatorid:2},
        {name:"Smart",number:961,length:3,operatorid:2},
        {name:"Smart",number:998,length:3,operatorid:2},
        {name:"Smart",number:999,length:3,operatorid:2},
        {name:"Globe Telecom",number:905,length:3,operatorid:3},
        {name:"Globe Telecom",number:906,length:3,operatorid:3},
        {name:"Globe Telecom",number:915,length:3,operatorid:3},
        {name:"Globe Telecom",number:916,length:3,operatorid:3},
        {name:"Globe Telecom",number:917,length:3,operatorid:3},
        {name:"Globe Telecom",number:926,length:3,operatorid:3},
        {name:"Globe Telecom",number:927,length:3,operatorid:3},
        {name:"Globe Telecom",number:935,length:3,operatorid:3},
        {name:"Globe Telecom",number:936,length:3,operatorid:3},
        {name:"Globe Telecom",number:937,length:3,operatorid:3},
        {name:"Globe Telecom",number:945,length:3,operatorid:3},
        {name:"Globe Telecom",number:953,length:3,operatorid:3},
        {name:"Globe Telecom",number:954,length:3,operatorid:3},
        {name:"Globe Telecom",number:955,length:3,operatorid:3},
        {name:"Globe Telecom",number:956,length:3,operatorid:3},
        {name:"Globe Telecom",number:965,length:3,operatorid:3},
        {name:"Globe Telecom",number:966,length:3,operatorid:3},
        {name:"Globe Telecom",number:967,length:3,operatorid:3},
        {name:"Globe Telecom",number:975,length:3,operatorid:3},
        {name:"Globe Telecom",number:976,length:3,operatorid:3},
        {name:"Globe Telecom",number:977,length:3,operatorid:3},
        {name:"Globe Telecom",number:978,length:3,operatorid:3},
        {name:"Globe Telecom",number:979,length:3,operatorid:3},
        {name:"Globe Telecom",number:995,length:3,operatorid:3},
        {name:"Globe Telecom",number:996,length:3,operatorid:3},
        {name:"Globe Telecom",number:997,length:3,operatorid:3},
        {name:"DITO",number:895,length:3,operatorid:4},
        {name:"DITO",number:896,length:3,operatorid:4},
        {name:"DITO",number:897,length:3,operatorid:4},
        {name:"DITO",number:898,length:3,operatorid:4},
        {name:"DITO",number:991,length:3,operatorid:4},
        {name:"DITO",number:992,length:3,operatorid:4},
        {name:"DITO",number:993,length:3,operatorid:4},
        {name:"DITO",number:994,length:3,operatorid:4},
      ];
      return res;
    },
    //根据手机号码获取运营商号码段信息
    GetOpertorsMobileRangeInfo(mobile){
       
      if(mobile==null || mobile.length<4 || mobile.length>15)
      {
        return null;
      }
      var one=mobile.substr(0,1);
      var num=0;
      if(one==0)
      {
        num=mobile.substr(1,3);//第0位为0，从第1位开始截取3位
      }
      else{
        num=mobile.substr(0,3);//从第0位开始截取3位
      }
      //var list=this.OperatorsMobileRangeList();
      var list=store.state.InitData['operatros_numbers'];
      var res=list.find(x=>x.number==num);
       
      return res;
    }
}


export default common